/* src/index.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

:root {
  --transparent: transparent;
  --current: currentColor;
  --primary: #465130; /* Dark olive */
  --secondary: #9EBC80; /* Middle Olive */
  --tertiary: #EAF0D8; /* Light Olive */
  --black: #000000;
  --white: #F7F7F7;
  --bg: #262525; /* Anjali Black */
  --red: #E87B7B;
  --gray-100: #f7fafc;
  --gray-200: #edf2f7;
  --gray-300: #e2e8f0;
  --gray-400: #cbd5e0;
  --gray-500: #a0aec0;
  --gray-600: #718096;
  --gray-700: #4a5568;
  --gray-800: #2d3748;
  --gray-900: #1a202c;
  --logoGray: #2f2d2d;
  --highlightRed: #FF3131;
}


body {
  margin: 0;
  font-family: 'League Spartan', sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
    font-family: 'League Spartan', sans-serif;
}

.carousel-container {
  position: absolute;
}

.carousel-slide {
  transition: transform 0.5s, z-index 0.5s;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide-active {
  transform: scale(1.1);
  z-index: 10;
}

/* Custom styles for Swiper navigation arrows */
.swiper-button-next,
.swiper-button-prev {
  color: #9EBC80; /* Change to your desired color */
  background: rgba(0, 0, 0, 0.5); /* Optional: Add background for better visibility */
  border-radius: 30%; /* Optional: Make the buttons circular */
  padding: 30px; /* Optional: Add some padding */
}

/* Custom styles for Swiper pagination dots */
.swiper-pagination-bullet {
  background: #9EBC80; /* Change to your desired color */
}

.swiper-pagination-bullet-active {
  background: #9EBC80; /* Change to your desired active color */
}

/* Placeholder color */
::placeholder {
  color: #9EBC80; /* Your desired color */
  opacity: 1; /* Ensure the color is fully opaque */
}

/* If you need to apply this to a specific class */
.placeholder-color::placeholder {
  color: #9EBC80; /* Your desired color */
  opacity: 1; /* Ensure the color is fully opaque */
  font-weight: bold
}

/* Add this CSS to your stylesheet */
.custom-input:focus {
  outline: #9EBC80;
}

/* src/components/SignInPopup.css */
.signin-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}
.signin-popup h2 {
  margin-bottom: 20px;
}
.signin-popup input {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.signin-popup button {
  display: block;
  width: 100%;
  padding: 10px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  width: 15px;
  height: 15px;
  padding: 0;
  margin: 0;
  cursor: pointer;
  vertical-align: middle;
  background-color: #fff;
  border: 2px solid var(--logoGray);
  border-radius: 50%;
  transition: background-color 0.3s, border-color 0.3s;
  position: relative; /* Required to position the :after element */
}

input[type="radio"]:checked {
  background-color: #9EBC80;
  border-color: #fff;
}

input[type="radio"]:checked:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* This will center the circle */
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #9EBC80;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  width: 15px;
  height: 15px;
  padding: 0;
  margin: 0;
  cursor: pointer;
  vertical-align: middle;
  border: 1px solid var(--logoGray);
  border-radius: 20%;
  transition: background-color 0.3s, border-color 0.3s;
  position: relative; /* Required to position the :after element */
}

input[type="checkbox"]:checked {
  background-color: #9EBC80;
  border-color: #9EBC80;
}

input[type="checkbox"]:checked:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* This will center the circle */
  width: 8px;
  height: 8px;
  border-radius: 20%;
  background-color: white;
}

@keyframes fillAnimation {
  0% {
      background-position: left;
  }
  100% {
      background-position: right;
  }
}

.fill-text {
  display: inline-block;
  background: linear-gradient(90deg, #a980bc, #9EBC80);
  /* background: linear-gradient(90deg, #9EBC80,  #465130); */
  background-size: 200% 100%;
  background-clip: text;
  color: transparent;
  animation: fillAnimation 1s forwards; /* Fill animation */
}

/* HTML: <div class="loader"></div> */
/* HTML: <div class="loader"></div> */
.loader {
  font-size: 40px;
  font-weight: bold;
  text-transform: uppercase;
  color: #0000;
  background: conic-gradient(var(--secondary) 0 0) 0/0% 100% no-repeat text;
  animation: l1 2s linear infinite;
}
.loader:before {
  content: "";
}
@keyframes l1 {
  to{background-size: 120% 100%}
}

/* Eyes */
.eyes {
  height: 30px;
  aspect-ratio: 2;
  background:
    radial-gradient(farthest-side,#000 15%,#0000 18%)0 0/50% 100%,
    radial-gradient(50% 100% at 50% 160%,#fff 95%,#0000) 0 0   /50% 50%,
    radial-gradient(50% 100% at 50% -60%,#fff 95%,#0000) 0 100%/50% 50%;
  background-repeat: repeat-x;
  /* -webkit-mask: radial-gradient(50% 100%,#000 95%,#0000) 0 100%/50% 0% repeat-x; */
  animation: l3 1s infinite alternate ease-in;
}
@keyframes l3 {
  0%,
  70% {-webkit-mask-size:50% 100%}
  85% {-webkit-mask-size:50% 0}
  100% {-webkit-mask-size:50% 100%}
}

/* For listing page animations */
@keyframes fadeInSlideUp {
  0% {
    opacity: 0;
    transform: translateY(100px);
    visibility: hidden; /* Elements are hidden initially */
  }
  1% {
    opacity: 0.1;
    visibility: visible; /* Make elements visible when animation starts */
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-slide-up {
  animation: fadeInSlideUp 1s ease-in-out forwards;
}

.fade-in-slide-up-delayed {
  animation-delay: 2s; /* Start after 1 second */
  animation: fadeInSlideUp 1.5s ease-in-out forwards;
}




.success-checkmark {
  width: 80px;
  height: 115px;
  margin: 0 auto;
  
  .check-icon {
      width: 80px;
      height: 80px;
      position: relative;
      border-radius: 50%;
      box-sizing: content-box;
      border: 4px solid var(--primary);
      
      &::before {
          top: 3px;
          left: -2px;
          width: 30px;
          transform-origin: 100% 50%;
          border-radius: 100px 0 0 100px;
      }
      
      &::after {
          top: 0;
          left: 30px;
          width: 60px;
          transform-origin: 0 50%;
          border-radius: 0 100px 100px 0;
          animation: rotate-circle 4.25s ease-in;
      }
      
      &::before, &::after {
          content: '';
          height: 100px;
          position: absolute;
          background: transparent;
          transform: rotate(-45deg);
      }
      
      .icon-line {
          height: 5px;
          background-color: var(--primary);
          display: block;
          border-radius: 2px;
          position: absolute;
          z-index: 10;
          
          &.line-tip {
              top: 46px;
              left: 14px;
              width: 25px;
              transform: rotate(45deg);
              animation: icon-line-tip 0.75s;
          }
          
          &.line-long {
              top: 38px;
              right: 8px;
              width: 47px;
              transform: rotate(-45deg);
              animation: icon-line-long 0.75s;
          }
      }
      
      .icon-circle {
          top: -4px;
          left: -4px;
          z-index: 10;
          width: 80px;
          height: 80px;
          border-radius: 50%;
          position: absolute;
          box-sizing: content-box;
          border: 4px solid var(--primary);
      }
  }
}

@keyframes rotate-circle {
  0% {
      transform: rotate(-45deg);
  }
  5% {
      transform: rotate(-45deg);
  }
  12% {
      transform: rotate(-405deg);
  }
  100% {
      transform: rotate(-405deg);
  }
}

@keyframes icon-line-tip {
  0% {
      width: 0;
      left: 1px;
      top: 19px;
  }
  54% {
      width: 0;
      left: 1px;
      top: 19px;
  }
  70% {
      width: 50px;
      left: -8px;
      top: 37px;
  }
  84% {
      width: 17px;
      left: 21px;
      top: 48px;
  }
  100% {
      width: 25px;
      left: 14px;
      top: 45px;
  }
}

@keyframes icon-line-long {
  0% {
      width: 0;
      right: 46px;
      top: 54px;
  }
  65% {
      width: 0;
      right: 46px;
      top: 54px;
  }
  84% {
      width: 55px;
      right: 0px;
      top: 35px;
  }
  100% {
      width: 47px;
      right: 8px;
      top: 38px;
  }
}

/* Debugging: Force styles directly on datepicker */
.react-datepicker {
  background-color: #f7fafc !important; /* Secondary color */
  border: 2px solid #805ad5 !important; /* Complement Purple */
  color: black !important;
  border-radius: 0.5rem !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
  padding: 1rem !important;
}

/* Header Styling */
.react-datepicker__header {
  background-color: var(--secondary) !important; /* Tertiary color */
  color: black !important;
  font-weight: bold !important;
  padding: 0.5rem !important;
  border-radius: 5rem 5rem 0 0 !important;
}

/* Day Cell Styling */
.react-datepicker__day {
  color: #000000 !important; /* Gray text */
  border-radius: 50% !important; /* Circular day cells */
  transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out !important;
}

.react-datepicker__day:hover {
  background-color: #805ad5 !important; /* Primary color */
  color: white !important;
}

/* Today's Date */
.react-datepicker__day--today {
  background-color: var(--tertiary) ; /* Light blue */
  color: var(--black); /* Darker blue */
  border: 2px solid var(--tertiary);
}

/* Selected Day */
.react-datepicker__day--selected {
  background-color: var(--secondary) !important; /* Light blue */
  color: var(--logoGray) !important; /* Darker blue */
  border: 2px solid var(--primary)!important;
}


/* Hide scrollbar */
.overflow-hidden-scrollbar {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.overflow-hidden-scrollbar::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.inverted-radius {
  --r: 2vw; /* The radius of the rounded corners */
  --s: 4vw; /* The size of the corner's "cut-out" size */

  aspect-ratio: 100; /* Maintain 1:1 aspect ratio */
  background: transparent;
  border-radius: var(--r);
  
  /* Radial gradient for masking */
  --_m: /calc(2 * var(--r)) calc(2 * var(--r)) radial-gradient(#000 70%, #0000 72%) no-repeat;

  /* Apply masking */
  mask:
    right 0 bottom calc(var(--s) + var(--r)) var(--_m),
    right calc(var(--s) + var(--r)) bottom 0 var(--_m),
    radial-gradient(var(--s) at 100% 100%, #0000 99%, #000 101%) calc(-1 * var(--r)) calc(-1 * var(--r)) no-repeat,
    conic-gradient(from 90deg at calc(100% - var(--s) - 2 * var(--r)) calc(100% - var(--s) - 2 * var(--r)), #0000 25%, #000 0);
}

/* 125% View */
@media (max-width: 1536px) {
  .inverted-radius {
    --r: 2.5vw; /* Increase the radius */
    --s: 6vw; /* Adjust the cut-out size */
  }
}


/* 150% View */
@media (max-width: 1280px) {
  .inverted-radius {
    --r: 2vw; /* Increase the radius */
    --s: 8vw; /* Adjust the cut-out size */
  }
}

/* Tablet View */
@media (max-width: 768px) {
  .inverted-radius {
    --r: 10vw; /* Increase the radius */
    --s: 18vw; /* Adjust the cut-out size */
  }
}

/* Mobile View */
@media (max-width: 480px) {
  .inverted-radius {
    --r: 7vw; /* Further increase the radius */
    --s: 21vw; /* Adjust the cut-out size for smaller screens */
    aspect-ratio: 1; /* Maintain 1:1 aspect ratio */

  }
}


.inverted-radius-forum {
  --r: 1.3vw; /* The radius of the rounded corners */
  --s: 2vw; /* The size of the corner's "cut-out" size */
  
  aspect-ratio: 2.8;
  border-radius: var(--r); /* Apply a rounded border to the element */
  
  /* Define a radial gradient for the mask */
  --_m: /calc(2 * var(--r)) calc(2 * var(--r)) radial-gradient(#000 70%, #0000 72%) no-repeat;

  /* Mask the corners using gradients and clipping paths */
  mask:
    /* Right bottom corner mask */
    right 0 bottom calc(var(--s) + var(--r)) var(--_m), /* 
       This part creates the inward curve for the bottom-right corner.
       It uses the radial gradient to form the inward cut effect.
    */
  
    /* Right top corner mask */
    right calc(var(--s) + var(--r)) bottom 0 var(--_m), /* 
       This part creates the inward curve for the top-right corner.
       Similar to the previous line, but positioned at the top-right.
    */
    
    /* Bottom-left corner inward curve */
    radial-gradient(var(--s) at 100% 100%, #0000 99%, #000 101%) 
    calc(-1 * var(--r)) calc(-1 * var(--r)) no-repeat, /* 
       This part handles the inward curve effect for the bottom-left corner. 
       The position and gradient create the inward, rounded cut for this corner.
    */
    
    /* Top-left corner inward curve */
    conic-gradient(from 90deg at calc(100% - var(--s) - 2 * var(--r)) calc(100% - var(--s) - 2 * var(--r)), 
    #0000 25%, #000 0); /* 
       This part applies a conic gradient from the top-left corner. 
       It adjusts the curve for the top-left corner, forming a slight cut.
    */
}

/* 125% View */
@media (max-width: 1536px) {
  .inverted-radius-forum {
    --r: 2vw; /* Increase the radius */
    --s: 2vw; /* Adjust the cut-out size */
  }
}

/* 150% View */
@media (max-width: 1280px) {
  .inverted-radius-forum {
    --r: 2vw; /* Increase the radius */
    --s: 3vw; /* Adjust the cut-out size */
    width: 100%; /* Slightly larger width for tablets */
  }
}


/* Tablet View */
@media (max-width: 768px) {
  .inverted-radius-forum {
    --r: 3.5vw; /* Increase the radius */
    --s: 12vw; /* Adjust the cut-out size */
    width: 100%; /* Slightly larger width for tablets */
  }
}

/* Mobile View */
@media (max-width: 480px) {
  .inverted-radius-forums {
    --r: 6vw; /* The radius of the rounded corners */
    --s: 8vw; /* The size of the corner's "cut-out" size */
    width: 89vw; /* Full width for mobile */
    aspect-ratio: 2.8; /* Maintain 1:1 aspect ratio */

  }
}

/* Blogs card css */
.inverted-radius-blogCard {
  --r: 1.5vw; /* The radius of the rounded corners */
  --s: 2.5vw; /* The size of the corner's "cut-out" size */
  
  aspect-ratio: 2.8;
  border-radius: var(--r); /* Apply a rounded border to the element */
  
  /* Define a radial gradient for the mask */
  --_m: /calc(2 * var(--r)) calc(2 * var(--r)) radial-gradient(#000 70%, #0000 72%) no-repeat;

  /* Mask the corners using gradients and clipping paths */
  mask:
    /* Right bottom corner mask */
    right 0 bottom calc(var(--s) + var(--r)) var(--_m), /* 
       This part creates the inward curve for the bottom-right corner.
       It uses the radial gradient to form the inward cut effect.
    */
  
    /* Right top corner mask */
    right calc(var(--s) + var(--r)) bottom 0 var(--_m), /* 
       This part creates the inward curve for the top-right corner.
       Similar to the previous line, but positioned at the top-right.
    */
    
    /* Bottom-left corner inward curve */
    radial-gradient(var(--s) at 100% 100%, #0000 99%, #000 101%) 
    calc(-1 * var(--r)) calc(-1 * var(--r)) no-repeat, /* 
       This part handles the inward curve effect for the bottom-left corner. 
       The position and gradient create the inward, rounded cut for this corner.
    */
    
    /* Top-left corner inward curve */
    conic-gradient(from 90deg at calc(100% - var(--s) - 2 * var(--r)) calc(100% - var(--s) - 2 * var(--r)), 
    #0000 25%, #000 0); /* 
       This part applies a conic gradient from the top-left corner. 
       It adjusts the curve for the top-left corner, forming a slight cut.
    */
}

/* 125% View */
@media (max-width: 1536px) {
  .inverted-radius-blogCard {
    --r: 2vw; /* Increase the radius */
    --s: 2vw; /* Adjust the cut-out size */
  }
}

/* 150% View */
@media (max-width: 1280px) {
  .inverted-radius-blogCard {
    --r: 2vw; /* Increase the radius */
    --s: 3vw; /* Adjust the cut-out size */
    width: 100%; /* Slightly larger width for tablets */
  }
}


/* Tablet View */
@media (max-width: 768px) {
  .inverted-radius-blogCard {
    --r: 3.5vw; /* Increase the radius */
    --s: 12vw; /* Adjust the cut-out size */
    width: 100%; /* Slightly larger width for tablets */
  }
}

/* Mobile View */
@media (max-width: 480px) {
  .inverted-radius-blogCard {
    --r: 2.4vw; /* The radius of the rounded corners */
    --s: 16vw; /* The size of the corner's "cut-out" size */
    width: 100%; /* Full width for mobile */
    aspect-ratio: 10; /* Maintain 1:1 aspect ratio */

  }
}

/* Container for the falling buttons */
.falling-container {
  width: 800px;
  height: 600px;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  background-color: #f5f5f5; /* Light gray background */
  border: 2px solid #ccc; /* Border for the container */
  border-radius: 8px; /* Rounded corners */
}

/* Labels for the buttons */
.falling-label {
  width: 150px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px; /* Rounded edges */
  font-size: 14px;
  font-weight: bold;
  color: #000; /* Black text color */
  pointer-events: none; /* Disable interaction */
  text-align: center;
  position: absolute;
  background-color: transparent; /* The rectangle body handles the background color */
}


/* 
.falling-container {
  width: 800px;
  height: 600px;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  background-color: #f5f5f5;
  border: 2px solid #ccc; 
  border-radius: 8px;
}

.falling-button {
  width: 150px;
  height: 50px;
  background-color: var(--secondary); 
  border-radius: 1.5rem; 
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff; 
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  user-select: none; 
  pointer-events: none; 
  position: absolute; 
  overflow: hidden;
} */


.mapbox {
    --mask: radial-gradient(40px at 100% 0,#0000 98%,#000);
    -webkit-mask: var(--mask);
            mask: var(--mask);
}

.getintouchbox {
    --mask: radial-gradient(40px at 100% 100%,#0000 98%,#000);
    -webkit-mask: var(--mask);
            mask: var(--mask);
}

/* Falling pills  */

/* Hide scrollbar for WebKit browsers (Chrome, Safari, Edge) */
.no-scrollbar::-webkit-scrollbar {
  display: none; /* Hides the scrollbar */
}

/* Hide scrollbar for Firefox */
.no-scrollbar {
  scrollbar-width: none; /* Hides the scrollbar */
}

/* General: Prevent scrollbar space affecting layout */
.no-scrollbar {
  -ms-overflow-style: none; /* Hides the scrollbar for IE 10+ */
  overflow: scroll; /* Ensure scrolling functionality is retained */
}