:root {
  --transparent: transparent;
  --current: currentColor;
  --primary: #465130; /* Dark olive */
  --secondary: #9EBC80; /* Middle Olive */
  --tertiary: #EAF0D8; /* Light Olive */
  --black: #000000;
  --white: #F7F7F7;
  --bg: #262525; /* Anjali Black */
  --red: #E87B7B;
  --gray-100: #f7fafc;
  --gray-200: #edf2f7;
  --gray-300: #e2e8f0;
  --gray-400: #cbd5e0;
  --gray-500: #a0aec0;
  --gray-600: #718096;
  --gray-700: #4a5568;
  --gray-800: #2d3748;
  --gray-900: #1a202c;
  --logoGray: #2f2d2d;
  --highlightRed: #FF3131;
}

.test-button {
  background-color: yellow;
  color: red;
}
/* CONTAINER */

ul {
  display: flex;
  flex-wrap: wrap;
  padding: 2em 0;
  width: 100%;
  margin: 0 auto;
  justify-content: space-around;
}

li {
  margin: 0.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}


/* BUTTON GLITCH */
.button-glitch,
.button-glitch:after {
width: 150px;
height: 76px;
line-height: 78px;
font-size: 20px;
font-family: 'Bebas Neue', sans-serif;
background: linear-gradient(45deg, transparent 5%, #FF013C 5%);
border: 0;
color: #fff;
letter-spacing: 3px;
box-shadow: 6px 0px 0px #00E6F6;
outline: transparent;
position: relative;
user-select: none;
-webkit-user-select: none;
touch-action: manipulation;
}

.button-glitch:after {
--slice-0: inset(50% 50% 50% 50%);
--slice-1: inset(80% -6px 0 0);
--slice-2: inset(50% -6px 30% 0);
--slice-3: inset(10% -6px 85% 0);
--slice-4: inset(40% -6px 43% 0);
--slice-5: inset(80% -6px 5% 0);

content: 'ALTERNATE TEXT';
display: block;
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #FF013C 5%);
text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
clip-path: var(--slice-0);
}

.button-glitch:hover:after {
animation: 1s glitch;
animation-timing-function: steps(2, end);
}

@keyframes glitch {
0% {
  clip-path: var(--slice-1);
  transform: translate(-20px, -10px);
}
10% {
  clip-path: var(--slice-3);
  transform: translate(10px, 10px);
}
20% {
  clip-path: var(--slice-1);
  transform: translate(-10px, 10px);
}
30% {
  clip-path: var(--slice-3);
  transform: translate(0px, 5px);
}
40% {
  clip-path: var(--slice-2);
  transform: translate(-5px, 0px);
}
50% {
  clip-path: var(--slice-3);
  transform: translate(5px, 0px);
}
60% {
  clip-path: var(--slice-4);
  transform: translate(5px, 10px);
}
70% {
  clip-path: var(--slice-2);
  transform: translate(-10px, 10px);
}
80% {
  clip-path: var(--slice-5);
  transform: translate(20px, -10px);
}
90% {
  clip-path: var(--slice-1);
  transform: translate(-10px, 0px);
}
100% {
  clip-path: var(--slice-1);
  transform: translate(0);
}
}

@media (min-width: 768px) {
.button-glitch,
.button-glitch:after {
  width: 200px;
  height: 86px;
  line-height: 88px;
}
}


/* BACKGROUND MOVE */
.button-background-move {
font-size: 16px;
font-weight: 200;
letter-spacing: 1px;
padding: 13px 20px 13px;
outline: 0;
border: 1px solid black;
cursor: pointer;
position: relative;
background-color: rgba(0, 0, 0, 0);
user-select: none;
-webkit-user-select: none;
touch-action: manipulation;
}

.button-background-move:after {
content: "";
background-color: #ffe54c;
width: 100%;
z-index: -1;
position: absolute;
height: 100%;
top: 7px;
left: 7px;
transition: 0.2s;
}

.button-background-move:hover:after {
top: 0px;
left: 0px;
}

@media (min-width: 768px) {
.button-background-move {
  padding: 13px 50px 13px;
}
}


/* PERPECTIVE */
.button-perspective {
background-color: #3DD1E7;
border: 0 solid #E5E7EB;
box-sizing: border-box;
color: #000000;
display: flex;
font-family: ui-sans-serif,system-ui,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
font-size: 1rem;
font-weight: 700;
justify-content: center;
line-height: 1.75rem;
padding: .75rem 1.65rem;
position: relative;
text-align: center;
text-decoration: none #000000 solid;
text-decoration-thickness: auto;
width: 100%;
max-width: 460px;
position: relative;
cursor: pointer;
transform: rotate(-2deg);
user-select: none;
-webkit-user-select: none;
touch-action: manipulation;
}

.button-perspective:focus {
outline: 0;
}

.button-perspective:after {
content: '';
position: absolute;
border: 1px solid #000000;
bottom: 4px;
left: 4px;
width: calc(100% - 1px);
height: calc(100% - 1px);
}

.button-perspective:hover:after {
bottom: 2px;
left: 2px;
}

@media (min-width: 768px) {
.button-perspective {
  padding: .75rem 3rem;
  font-size: 1.25rem;
}
}

/* PAPER */
.button-paper {
align-self: center;
background-color: #fff;
background-image: none;
background-position: 0 90%;
background-repeat: repeat no-repeat;
background-size: 4px 3px;
border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
border-style: solid;
border-width: 2px;
box-shadow: rgba(0, 0, 0, .2) 15px 28px 25px -18px;
box-sizing: border-box;
color: #41403e;
cursor: pointer;
display: inline-block;
font-family: Neucha, sans-serif;
font-size: 1rem;
line-height: 23px;
outline: none;
padding: .75rem;
text-decoration: none;
transition: all 235ms ease-in-out;
border-bottom-left-radius: 15px 255px;
border-bottom-right-radius: 225px 15px;
border-top-left-radius: 255px 15px;
border-top-right-radius: 15px 225px;
user-select: none;
-webkit-user-select: none;
touch-action: manipulation;
}

.button-paper:hover {
box-shadow: rgba(0, 0, 0, .3) 2px 8px 8px -5px;
transform: translate3d(0, 2px, 0);
}

.button-paper:focus {
box-shadow: rgba(0, 0, 0, .3) 2px 8px 4px -6px;
}


/* COVER */
.button-cover {
  position: relative;
  overflow: hidden;
  border: 1px solid var(--gray-300);
  color: black;
  display: inline-block;
  font-size: 40px;
  line-height: 15px;
  width: 50px; /* Set width */
  height: 50px; /* Set height */
  padding: 0; /* Remove padding */
  text-decoration: none;
  cursor: pointer;
  background: var(--secondary);
  border-radius: 50%; /* Make it circular */
  /* font-family: Arial, sans-serif; */
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-cover span:first-child {
  position: relative;
  transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
  z-index: 10;
}

.button-cover span:last-child {
  color: white;
  display: block;
  font-size: 15px;
  position: absolute;
  bottom: 0;
  transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
  z-index: 100;
  opacity: 0;
  top: 65%;
  left: 50%;
  transform: translateY(225%) translateX(-50%);
  height: 14px;
  line-height: 13px;
}

.button-cover:after {
  content: "";
  position: absolute;
  bottom: -50%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  transform-origin: bottom center;
  transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
  transform: skewY(9.3deg) scaleY(0);
  z-index: 50;
}

.button-cover:hover:after {
  transform-origin: bottom center;
  transform: skewY(9.3deg) scaleY(2);
}

.button-cover:hover span:last-child {
  transform: translateX(-50%) translateY(-100%);
  opacity: 1;
  transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
}

.square-button-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 20px; /* Reduced vertical space between rows */
  grid-column-gap: 20px; /* Horizontal space between columns */
}


/* Square Button */
.square-button {
  position: relative;
  overflow: hidden;
  border: 3px solid var(--secondary); /* Updated border color */
  color: var(--secondary);
  display: inline-block;
  font-size: 18px;
  line-height: normal;
  width: 150px; /* Set width */
  height: 150px; /* Set height */
  padding: 0px; /* Remove padding */
  margin: 5px;
  text-decoration: none;
  cursor: pointer;
  background: var(--tertiary);
  border-radius: 25px; /* Increased border radius */
  font-family: 'Raleway', sans-serif; /* Raleway font */
  font-weight: bold; /* Bold text */
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  text-align: left;
}

.square-button span:first-child {
  position: absolute;
  bottom: 12px; /* Position at the bottom */
  left: 12px; /* Align text to the left */
  transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
  z-index: 10;
  font-family: 'Raleway', sans-serif; /* Raleway font */
  font-weight: bold; /* Bold text */
}

.square-button span:last-child {
  color: white;
  font-size: 15px;
  position: absolute;
  bottom: 35px; /* Align to the bottom */
  left: 10px; /* Align text to the left */
  transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
  z-index: 100;
  opacity: 0;
  transform: translateY(225%);
  font-family: 'Raleway', sans-serif; /* Raleway font */
  font-weight: bold; /* Bold text */
  height: 20px;
  line-height: 18px;
  text-align: left; /* Align text to the left */
}

.square-button:after {
  content: "";
  position: absolute;
  bottom: -50%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  transform-origin: bottom center;
  transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
  transform: skewY(9.3deg) scaleY(0);
  z-index: 50;
}

.square-button:hover:after {
  transform-origin: bottom center;
  transform: skewY(9.3deg) scaleY(2);
}

.square-button:hover span:last-child {
  transform: translateY(-100%);
  opacity: 1;
  transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
}

/* For Receipts */
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.receipt-header {
  display: flex;
  justify-content: flex-start; /* Aligns items to the start (left) */
  align-items: center;
}

.receipt-header .display-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 8px; /* Space between the name and timestamp */
  flex: 1; /* Allows the name to take available space */
}

.receipt-header .timestamp {
  margin-left: 8px;
  flex-shrink: 0; /* Prevents shrinking of timestamp */
}

.custom-calendar {
  border: 2px solid #6b46c1; /* Example: Purple border */
  border-radius: 10px;
  background-color: #f7fafc; /* Example: Light gray background */
}
